import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./css/main.css"
import Home from "./components/Home";
import About from "./components/About";
const rootElement = document.getElementById("root");
render(
  <>
    <div className="window">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
      </Routes>
    </BrowserRouter>
    </div>
  </>,
  rootElement
);
