import { useState, useEffect, useRef } from "react";

function Home() {
  const [search, setSearch] = useState(true);
  const [name, setName] = useState(true);
  const [url, setUrl] = useState(true);
  const [text, setText] = useState(true);
  const [scores, setScores] = useState(null)

  const [feedbacks, setFeedbacks] = useState(null);
  const refName = useRef("");
  const refUrl = useRef("");
  const refText = useRef("");

  const [score, setScore] = useState(1);


  useEffect(() => {
    fetch("http://localhost:5000/feedback")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setFeedbacks(data);
      });

  }, []);


  function Stars() {
    if (score === 1) {
      return (
        <>
          <img
            className="score-button "
            onClick={() => setScore(1)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(2)}
            src="../star_gray.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(3)}
            src="../star_gray.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(4)}
            src="../star_gray.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(5)}
            src="../star_gray.png"
            alt=""
          />
        </>
      );
    }
    if (score === 2) {
      return (
        <>
          <img
            className="score-button "
            onClick={() => setScore(1)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(2)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(3)}
            src="../star_gray.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(4)}
            src="../star_gray.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(5)}
            src="../star_gray.png"
            alt=""
          />
        </>
      );
    }
    if (score === 3) {
      return (
        <>
          <img
            className="score-button "
            onClick={() => setScore(1)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(2)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(3)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(4)}
            src="../star_gray.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(5)}
            src="../star_gray.png"
            alt=""
          />
        </>
      );
    }
    if (score === 4) {
      return (
        <>
          <img
            className="score-button "
            onClick={() => setScore(1)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(2)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(3)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(4)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(5)}
            src="../star_gray.png"
            alt=""
          />
        </>
      );
    }
    if (score === 5) {
      return (
        <>
          <img
            className="score-button "
            onClick={() => setScore(1)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(2)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(3)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(4)}
            src="../star.png"
            alt=""
          />
          <img
            className="score-button "
            onClick={() => setScore(5)}
            src="../star.png"
            alt=""
          />
        </>
      );
    }
  }


  function searchTrue() {
    setSearch(true);
  }
  function searchFalse() {
    setSearch(false);
  }
  function SearchElement() {
    return (
      <>
        <label className="input-label">Название сервиса:</label>
        <input className="input" />
        <label className="input-label">Ссылка:</label>
        <input className="input" />
        <button className="button max">Найти</button>
      </>
    );
  }
  function WriteElement() {
    return (
      <>
        <label className="input-label">Название сервиса:</label>
        <input id="name" ref={refName}  className="input" />
        <label className="input-label">Ссылка:</label>
        <input id="url" ref={refUrl} className="input" />
        <label className="input-label">Дополнительно:</label>
        <textarea id="text" ref={refText} className="input area"></textarea>

        <div className="block head mt-10"></div>

        <Stars/>
        <button className="button max" onClick={() => newFeedback()}>
          Сохранить
        </button>
      </>
    );
  }

  

  async function newFeedback() {
    console.log();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: 0,
        score: score,
        url: refUrl.current.value,
        text: refText.current.value,
        name: name,
        owner: "anon",
        status: 0,
      }),
    };
    fetch("http://localhost:5000/feedback", requestOptions)
      .then((response) => response.json())
      .then((data) => this.setState({ postId: data.id }));
  }

  return (
    <div className="block centre main">
      {search ? (
        <>
          <div className="block head ">
            <button className="button none" onClick={() => searchTrue()}>
              Найити отзыв
            </button>
            <button className="button " onClick={() => searchFalse()}>
              Написать отзыв
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="block head">
            <button className="button" onClick={() => searchTrue()}>
              Найити отзыв
            </button>
            <button className="button none" onClick={() => searchFalse()}>
              Написать отзыв
            </button>
          </div>
        </>
      )}

      <div className="block head mt-10">
        {search ? (
          <>
            <SearchElement />
          </>
        ) : (
          <>
            <WriteElement />
          </>
        )}
      </div>
    </div>
  );
}

export default Home;
